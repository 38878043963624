import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TrackVisibility from 'react-on-screen';
import logo from '../assets/img/logo.svg';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Fintech", "Finance Tech", "Financial Technologies"];
  const period = 500;

  useEffect(() => {
    const ticker = setInterval(() => {
      tick();
    }, delta);

    return () => clearInterval(ticker);
  }, [text]);

  const tick = () => {
    const i = loopNum % toRotate.length;
    const fullText = toRotate[i];
    const updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(300 - Math.random() * 100);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center homeimage">
        <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={logo} alt="Header Img" />
                </div>
              )}
            </TrackVisibility>
          </Row>
        <Row className="align-items-center">
          {/* <Col xs={12} md={6} xl={7}> */}
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h1>
                    {'Welcome to Nuera'}{" "}
                    <span className="txt-rotate" data-period="1000" data-rotate='["Fintech", "Finance Tech", "Financial Technologies"]'>
                      <span className="wrap">{text}</span>
                    </span>
                  </h1>
                  <p>
                    At Nuera Fintech, we are committed to revolutionizing the financial industry with our cutting-edge algorithmic trading software and comprehensive fintech solutions. Our mission is to empower traders, investors, and financial institutions with innovative tools that enhance performance and drive success.
                  </p>
                </div>
              )}
            </TrackVisibility>
          {/* </Col> */}
          {/* <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={logo} alt="Header Img" />
                </div>
              )}
            </TrackVisibility>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};
