import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img alt="bgimage" src={imgUrl} />
        <div className="proj-txtx">
          <h3>{title}</h3>
          <span>{description}</span>
          <br/> <br/>
          <h4>Coming soon...</h4>
          <h3>@ Just ₹599/Month</h3>
          <button className="vvd2"><span>Subscribe Now</span></button>
        </div>
      </div>
    </Col>
  )
}
