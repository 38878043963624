import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"

export const Whychooseus = () => {

  return (
    <section className="skill" id="whychooseus">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h1>Why Choose Us</h1>
                        <h2>Innovation at Core</h2>
                        <p>At Nuera Fintech, innovation is not just a buzzword; it's the driving force behind everything we do. We are constantly exploring new technologies and methodologies to improve our offerings.</p>
                        <h2>Expertise and Experience</h2>
                        <p>Our team comprises seasoned professionals with extensive experience in finance and technology, ensuring that we deliver solutions that are both effective and reliable.</p>
                        <h2>Client-Centric Approach</h2>
                        <p>We believe in building lasting relationships with our clients. Our solutions are tailored to meet your specific needs, and our support team is always ready to assist you.</p>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="bgimage" />
    </section>
  )
}
