
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 5
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1
  //   }
  // };

  return (
    <section className="skill" id="aboutus">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Who We Are</h2>
                        <p>Nuera Fintech is a leading fintech company dedicated to developing advanced trading technologies and financial software solutions. With a team of experienced professionals and tech enthusiasts, we are at the forefront of financial innovation, constantly pushing the boundaries to deliver excellence.</p>
                        <h2>Our Vision</h2>
                        <p>To transform the financial landscape with innovative solutions that offer reliability, efficiency, and scalability.</p>
                        <h2>Our Mission</h2>
                        <p>To empower our clients with state-of-the-art technology, enabling them to optimize their trading strategies and achieve superior financial outcomes.</p>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="bgImage" />
    </section>
  )
}
